import { processClient } from './client'
import { processImageSEO } from './image'
import { extractRichText } from './rich-text'

export const processCaseStudy = (cs: CaseStudySource): CaseStudy => ({
  ...cs,
  lead: cs.lead.lead,
  firstPart: extractRichText(cs.firstPart.firstPart),
  secondPart: extractRichText(cs.secondPart.secondPart),
  client: processClient(cs.client),
  socialImage: processImageSEO(cs.socialImage),
})

export const extractCaseStudyPreviewSection = (
  data?: SectionItemsSource<CaseStudyPreviewSource>,
): SectionItems<CaseStudyPreview> => ({
  headline: (data && data.headline) || '',
  items: (data && data.items.map(processCaseStudyPreview)) || [],
})

export const processCaseStudyPreview = (
  cs: CaseStudyPreviewSource | CaseStudySource,
): CaseStudyPreview => ({
  ...cs, lead: cs.lead.lead, client: processClient(cs.client),
})
