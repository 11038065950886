import { processImageFull } from './image'

export const extractClientSection = (
  data?: SectionItemsSource<ClientSource>,
): SectionItems<Client> => ({
  headline: (data && data.headline) || '',
  items: (data && data.items.map(processClient)) || [],
})

export const processClient = ({ name, url, logo }: ClientSource): Client => ({
  name, url, logo: processImageFull(logo),
})
