import { processImageFull } from './image'

export const extractTechnologySection = (
  data?: SectionItemsSource<TechnologySource>,
): SectionItems<Technology> => ({
  headline: (data && data.headline) || '',
  items: (data && data.items.map(processTechnology)) || [],
})

export const processTechnology = ({ name, logo }: TechnologySource): Technology => ({
  name,
  logo: processImageFull(logo),
})
