import React, { HTMLAttributes } from 'react'

import { HeadingTag } from '../../enums'

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  tag: HeadingTag
}

export const Heading: React.FunctionComponent<Props> =
  ({ tag: Tag, children, ...rest }) => <Tag {...rest}>{children}</Tag>
