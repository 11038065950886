import React from 'react'
import {
  documentToReactComponents,
  RenderMark,
  RenderNode,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS, Text } from '@contentful/rich-text-types'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
// if a different style is needed, replace `darcula` with its name
import { darcula as syntaxStyle } from 'react-syntax-highlighter/dist/esm/styles/prism'
// when syntax for new languages is needed, it should be imported here
// and added to `languageMapping` below
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript'
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx'
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python'
import django from 'react-syntax-highlighter/dist/esm/languages/prism/django'
import slugify from 'slugify'

import CodeContainer from '../components/CodeContainer'
import RichTextLink from '../components/RichTextLink'
import TocAnchor from '../components/TocAnchor'
import { ENUMERATED_ELEMENT_CLASS } from '../constants'

const DEFAULT_LANGUAGE = 'javascript'
const languageMapping: {[lang: string]: SyntaxHighlighterLanguageFunc} = {
  javascript: javascript as SyntaxHighlighterLanguageFunc,
  jsx: jsx as SyntaxHighlighterLanguageFunc,
  python: python as SyntaxHighlighterLanguageFunc,
  django: django as SyntaxHighlighterLanguageFunc,
}
const SUPPORTED_LANGUAGES = new Set<string>(Object.keys(languageMapping))
SUPPORTED_LANGUAGES.forEach(lang => {
  SyntaxHighlighter.registerLanguage(lang, languageMapping[lang])
})

interface RichTextOptions {
  renderMark: RenderMark,
  renderNode: RenderNode,
}

const richTextOptions: RichTextOptions = {
  renderMark: {
    [MARKS.CODE]: node => {
      if (!node) {
        return null
      }
      let language = DEFAULT_LANGUAGE
      let content = node.toString().split('\n')
      if (content.length > 0 && SUPPORTED_LANGUAGES.has(content[0])) {
        language = content[0]
        content = content.slice(1)
      }
      return (
        // span is used as the container because <pre/> is not a valid child of <p/>
        <SyntaxHighlighter
          language={language}
          style={syntaxStyle as React.CSSProperties}
          PreTag={CodeContainer}
        >
          {content.join('\n')}
        </SyntaxHighlighter>
      )
    },
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const target = node.data.target as EmbeddedAssetTarget
      if (!target.fields) {
        return null
      }
      const { title, description, file } = target.fields
      const mimeType = file['en-US'].contentType
      const mimeGroup = mimeType.split('/')[0]
      const imgTitle = title ? title['en-US'] : ''

      switch (mimeGroup) {
        case 'image':
          return <img
            title={imgTitle}
            alt={description ? description['en-US'] : imgTitle}
            src={file['en-US'].url}
            className='no-margin-btm'
          />
        default:
          return null
      }
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      const text = node.content
        .filter(content => content.nodeType === 'text')
        .map((content: Text) => content.value || '').join('')
      const name = slugify(text, { lower: true, strict: true })
      return (
        <TocAnchor name={name} text={text}>
          <h3 className={ENUMERATED_ELEMENT_CLASS}>{children}</h3>
        </TocAnchor>
      )
    },
    [INLINES.HYPERLINK]: node => {
      const uri = node.data.uri as string
      let value = uri
      if (node.content.length > 0) {
        value = (node.content[0] as Text).value
      }
      return <RichTextLink href={uri}>{value}</RichTextLink>
    },
  },
}

export const extractRichText = (field: string): React.ReactNode => (
  documentToReactComponents(JSON.parse(field), richTextOptions)
)
