const extractImageInfo = ({ id, title, description }: ImageDetailsBase): ImageDetailsWithAlt => ({
  id, title, description, alt: description || title || '',
})

export const processImageFixed = (image: FixedImageSource): FixedImage => ({
  ...extractImageInfo(image),
  fixed: image.fixed,
})

export const processImageFluid = (image: FluidImageSource): FluidImage => ({
  ...extractImageInfo(image),
  fluid: image.fluid,
})

export const processImageFull = (image: FullImageSource): FullImage => ({
  ...extractImageInfo(image),
  url: image.file.url,
  fileName: image.file.fileName,
  contentType: image.file.contentType,
  width: image.file.details.image.width,
  height: image.file.details.image.height,
})

export const processImageSEO = (image: SEOImageSource | FullImageSource): SEOImage => ({
  id: image.id,
  url: image.file.url,
  width: image.file.details.image.width,
  height: image.file.details.image.height,
})
