import React from 'react'

import styles from './code-container.module.scss'

interface Props {
  style: React.CSSProperties,
}

// style and children are passed in when used in `PreTag` prop of 'react-syntax-highlighter'
export const CodeContainer: React.FunctionComponent<Props> = ({ style, children }) => (
  <span style={style} className={styles.codeContainer}>{children}</span>
)
