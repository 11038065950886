import { processImageFluid } from './image'

export const extractTeamMemberSection = (
  data?: SectionItemsSource<TeamMemberSource>,
): SectionItems<TeamMember> => ({
  headline: (data && data.headline) || '',
  items: (data && data.items.map(processTeamMember)) || [],
})

export const processTeamMember = (teamMember: TeamMemberSource): TeamMember => ({
  ...teamMember,
  photo: processImageFluid(teamMember.photo),
})
