import { processImageFixed } from './image'

export const extractServiceSection = (
  data?: SectionItemsSource<ServiceSource>,
): SectionItems<Service> => ({
  headline: (data && data.headline) || '',
  items: (data && data.items.map(processService)) || [],
})

export const processService = (service: ServiceSource): Service => ({
  ...service,
  copy: service.copy.copy,
  icon: processImageFixed(service.icon),
})
