import React from 'react'
import { Element } from 'react-scroll'

import { TOC_DATA_TEXT_ATTR, TOC_ANCHOR_CLASS } from '../../constants'

interface Props {
  name: string
  text: string
  anchorClass?: string
  dataTextAttr?: string
}

export const TocAnchor: React.FC<Props> = ({
  children,
  name,
  text,
  anchorClass = TOC_ANCHOR_CLASS,
  dataTextAttr = TOC_DATA_TEXT_ATTR,
}) => {
  const dynamicProps = {
    [dataTextAttr]: text,
  }
  return (
    <>
      <Element name={name} className={anchorClass} {...dynamicProps}/>
      {children}
    </>
  )
}
