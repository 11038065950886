import { processImageFull } from './image'

export const extractReviewSection = (
  data?: SectionItemsSource<ReviewSource>,
): SectionItems<Review> => ({
  headline: (data && data.headline) || '',
  items: (data && data.items.map(processReview)) || [],
})

export const processReview = (review: ReviewSource): Review => ({
  ...review,
  clientLogo: processImageFull(review.clientLogo),
})
