import React from 'react'
import { Link } from 'gatsby'

import { getInternalPath } from '../../utils'

import styles from './rich-text-link.module.scss'

interface Props {
  href: string
}

export const RichTextLink: React.FunctionComponent<Props> = ({ children, href }) => {
  const props = {
    className: styles.richTextLink,
  }
  const internalPath = getInternalPath(href)
  if (internalPath) {
    return <Link {...props} to={internalPath}>{children}</Link>
  }
  return (
    <a {...props} href={href} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  )
}

RichTextLink.displayName = 'Rich text link'
