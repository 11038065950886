import React, { CSSProperties } from 'react'

import styles from './addons.module.scss'

interface Props {
  top?: AddonTop
  bottom?: AddonBottom
  freeForm?: Addon
}

export const Addons: React.FC<Props> = ({ freeForm, top, bottom }) =>
  <>
    {top ? (
      <div
        style={{ top: top.top }}
        className={`${styles.addon} ${styles.addonCentered} ${top.className || ''}`}
      >
        {top.addon}
      </div>
    ) : <noscript/>}
    {bottom ? (
      <div
        style={{ bottom: bottom.bottom }}
        className={`${styles.addon} ${styles.addonCentered} ${bottom.className || ''}`}
      >
        {bottom.addon}
      </div>
    ) : <noscript/>}
    {freeForm ? (
      <div
        style={freeFormStyles(freeForm)}
        className={`${styles.addon} ${freeForm.className || ''}`}
      >
        {freeForm.addon}
      </div>
    ) : <noscript/>}
  </>

const freeFormStyles = (addon: Addon): CSSProperties => {
  return Object
    .keys(addon)
    .filter(key => key !== 'addon' && key !== 'className')
    .reduce((obj, key) => {
      return { ...obj, [key]: addon[key as keyof Addon] }
    }, {})
}
