import React, { FunctionComponent } from 'react'

import Addons from '../../components/Addons'
import Heading from '../../components/Heading'
import { HeadingTag } from '../../enums'
import { placeWithinContainer, optionalClasses, useContainerOffset, Padding } from './utils'

import styles from './section.module.scss'

// TODO: don't add any more props before refactoring
interface Props {
  headline?: string
  dark?: boolean
  white?: boolean
  light?: boolean
  addons?: {
    top?: AddonTop,
    bottom?: AddonBottom,
    freeForm?: Addon,
  }
  topPadding?: Padding
  bottomPadding?: Padding
  grid?: boolean
  className?: string
  containerClassName?: string
  headlineDecoration?: string
  headlineTag?: HeadingTag
  headlineClassName?: string
}

export const Section: FunctionComponent<Props> = ({
  children, headline, dark = false, white = false, light = false,
  topPadding = 'normal', bottomPadding = 'normal', addons, grid = false,
  className = '', containerClassName = '', headlineDecoration, headlineTag,
  headlineClassName,
}) => {
  const [containerOffset, sectionEl, containerEl] = useContainerOffset()
  return (
    <section
      className={`${styles.section} ${className}
      ${optionalClasses(dark, white, light, topPadding, bottomPadding)}`}
      ref={sectionEl}
    >
      <div className={calcContainerClasses(containerClassName, grid)} ref={containerEl}>
        {headline && (
          <Headline
            headline={headline}
            decoration={headlineDecoration}
            dark={dark}
            headingTag={headlineTag}
            headingClassName={headlineClassName}
          />
        )}
        {children}
      </div>
      {addons && <Addons {...{
        ...addons,
        freeForm: placeWithinContainer(addons.freeForm, containerOffset, containerEl.current),
      }}/>}
    </section>
  )
}

interface HeadlineProps {
  headline: string
  decoration?: string
  dark?: boolean
  headingTag?: HeadingTag
  headingClassName?: string
}

const Headline: React.FunctionComponent<HeadlineProps> = ({
  headline,
  decoration,
  dark,
  headingTag = HeadingTag.H2,
  headingClassName,
}) =>
  <div className={`${styles.headline} cell`}>
    <Heading tag={headingTag} className={`${dark ? 'dark-bg' : ''} ${headingClassName || ''}`}>
      {headline}
    </Heading>
    { decoration && <HeadlineDecoration src={decoration}/> }
  </div>

interface DecorationProps {
  src: string
}

const HeadlineDecoration = ({ src }: DecorationProps) =>
  <div className={styles.headlineIllustration}>
    <img src={src} alt='Section headline decoration'/>
  </div>

const calcContainerClasses = (containerClassName: string, grid: boolean): string =>
  `
    container
    ${containerClassName}
    ${grid ? 'grid-x grid-margin-x' : ''}
  `
