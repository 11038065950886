import { processAuthor } from './author'
import { processSEO } from './seo'
import { extractRichText } from './rich-text'

export const extractArticles = (data: ArticlesQueryData): Article[] =>
  data.articles.edges.map(a => processArticle(a.node))

export const processArticle = (a: ArticleSource): Article => ({
  ...a,
  lead: a.lead.lead,
  firstPart: extractRichText(a.firstPart.firstPart),
  secondPart: extractRichText(a.secondPart.secondPart),
  thirdPart: a.thirdPart && extractRichText(a.thirdPart.thirdPart),
  seo: processSEO(a.seo),
  author: processAuthor(a.author),
})
