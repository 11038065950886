import { extractRichText } from './rich-text'

export const processLeadData = (leadData?: SectionSource<LeadSource>): Lead => (
  leadData && leadData.items.length > 0
    ? processLead(leadData.items[0])
    : { headline: '', content: '' }
)

export const processLead = (lead: LeadSource): Lead => ({
  headline: lead.headline,
  content: extractRichText(lead.text.text),
})
