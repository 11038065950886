import { processSEO } from './seo'

export const processContext = (data: PageQueryData): PageContext => {
  const node = { ...(data.page.edges[0].node) }
  return ({
    ...node,
    subheadline: (node.subheadline && node.subheadline.subheadline) || '',
    seo: processSEO(node.seo),
  })
}
