import React from 'react'

import styles from './section.module.scss'

type HookReturn = [
  number,
  React.RefObject<HTMLElement>,
  React.RefObject<HTMLDivElement>,
]

export const useContainerOffset = (): HookReturn => {
  const [containerOffset, setContainerOffset] = React.useState(0)
  const sectionEl = React.useRef<HTMLElement>(null)
  const containerEl = React.useRef<HTMLDivElement>(null)

  const updateContainerOffset = () => {
    if (containerEl.current && sectionEl.current) {
      const offset = (sectionEl.current.clientWidth - containerEl.current.clientWidth) / 2
      offset !== containerOffset && setContainerOffset(offset)
    }
  }

  React.useEffect(() => {
    updateContainerOffset()

    window.addEventListener('resize', updateContainerOffset)
    return () =>
      window.removeEventListener('resize', updateContainerOffset)
  })

  return [containerOffset, sectionEl, containerEl]
}

export const placeWithinContainer = (
  freeForm: Addon | undefined, offset: number, containerEl: HTMLDivElement | null,
): Addon | undefined => {
  if (freeForm && containerEl) {
    if (freeForm.left) {
      let containerLeft = 0
      if (freeForm.left.indexOf('%')) {
        containerLeft = containerEl.clientWidth * parseInt(freeForm.left.replace('%', ''), 10) / 100
      } else if (freeForm.left.indexOf('px')) {
        containerLeft = parseInt(freeForm.left.replace('%', ''), 10)
      }
      const leftAdjusted = offset + containerLeft
      return {
        ...freeForm,
        left: `${leftAdjusted}px`,
      }
    }
    // TODO Add handling for right
  }
  return freeForm
}

export type Padding = 'none' | 'small' | 'normal' | 'large' | 'xlarge' | 'huge'

export const optionalClasses = (
  dark: boolean,
  white: boolean,
  light: boolean,
  topPadding: Padding,
  bottomPadding: Padding,
): string => {
  const classes = []
  dark && classes.push(styles.sectionDark)
  white && classes.push(styles.sectionWhite)
  light && classes.push(styles.sectionLight)
  classes.push(styles[`topPadding${topPadding[0].toUpperCase()}${topPadding.slice(1)}`])
  classes.push(styles[`bottomPadding${bottomPadding[0].toUpperCase()}${bottomPadding.slice(1)}`])

  return classes.join(' ')
}
